import { graphql } from 'gatsby';

import { Page } from '../components/layout';
import { GROUP_BRAND } from '../hooks/use-locations';

import Logos from './shared/logos';
import Teaser from './shared/teaser';
import Categories from './shared/categories';
import * as styles from './brands.module.scss';

const Brands = ({
	data: {
		markdownRemark: {
			frontmatter: { teaser },
			fields: { slug },
			html,
		},
	},
}) => (
	<Page
		slug={slug}
		body={html}
		teaser={teaser ? <Teaser title={teaser.title} image={teaser.image} /> : null}>
		<div className={styles.logos}>
			<Logos group={GROUP_BRAND} />
		</div>
		<Categories group={GROUP_BRAND} />
	</Page>
);

export const brandsQuery = graphql`
	query brandsQuery($remarkId: String!) {
		markdownRemark(id: { eq: $remarkId }) {
			frontmatter {
				teaser {
					title
					image {
						childImageSharp {
							gatsbyImageData(layout: FULL_WIDTH)
						}
					}
				}
			}
			fields {
				slug
			}
			html
		}
	}
`;

export default Brands;
